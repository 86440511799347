.p-algorithm {
  margin-top: 15px;
}

.p-algorithm .layout-container {
  display: flex;
}

.p-algorithm .layout-container .side-layout {
  width: 300px;
  padding: 15px 15px 15px 0;
  box-sizing: border-box;
  /* border-right: 1px solid var(--border-light-grey); */
}

@media screen and (max-width: 900px) {
  .p-algorithm .layout-container .side-layout {
    display: none;
  }
}

.p-algorithm .layout-container .body-layout {
  flex: 1;
  padding: 5px 0 0 0;
}
