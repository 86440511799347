.c-group-list .title-divider {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.c-group-list .title-divider .divider {
  flex: 1;
  margin-right: 20px;
}

.c-group-list .group-container {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.c-group-list .group-container .group-item {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1200px) {
  .c-group-list .group-container {
    grid-template-columns: 33% 33% 33%;
  }
}

@media screen and (max-width: 1024px) {
  .c-group-list .group-container {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 768px) {
  .c-group-list .group-container {
    grid-template-columns: 100%;
  }
}

.c-group-list .group-container .group-item .ant-card-cover {
  margin: 0;
}

.c-group-list .group-container .group-item .group-head {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}

.c-group-list .group-empty {
  padding: 30px 0;
}
