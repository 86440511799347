/* React-Markdown 样式覆盖 */

.react-markdown h1,
.react-markdown h2,
.react-markdown h3,
.react-markdown h4 {
  color: var(--black);
  font-weight: normal;
  margin-top: 1em;
}
.react-markdown h1,
.react-markdown h2,
.react-markdown h3,
.react-markdown h4,
.react-markdown h5,
.react-markdown p,
.react-markdown dl {
  margin-bottom: 16px;
  padding: 0;
}
.react-markdown h1 {
  font-size: 36px;
  line-height: 44px;
}
.react-markdown h2 {
  font-size: 31px;
  line-height: 38px;
}
.react-markdown h1,
.react-markdown h2 {
  border-bottom: 1px solid var(--border-light-grey);
  padding-bottom: 10px;
}
.react-markdown h3 {
  font-size: 25px;
  line-height: 32px;
}
.react-markdown h4 {
  font-size: 20px;
  line-height: 24px;
}
.react-markdown h5 {
  font-size: 18px;
  list-style: 23px;
}
.react-markdown a {
  color: var(--topic);
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
.react-markdown a:hover {
  /* text-decoration: none;
  color: #ff6600; */
}
.react-markdown a:visited {
  /*color: purple;*/
}
.react-markdown ul,
.react-markdown ol {
  padding: 0;
  padding-left: 24px;
  margin: 0;
}
.react-markdown li {
  line-height: 24px;
}
.react-markdown p,
.react-markdown ul,
.react-markdown ol {
  font-size: 15px;
  line-height: 24px;
}
.react-markdown ol ol,
.react-markdown ul ol {
  list-style-type: lower-roman;
}
.react-markdown code,
.react-markdown pre {
  border-radius: 3px;
  background-color: var(--bg-light-grey);
  color: inherit;
}
.react-markdown code {
  font-family: Consolas, Monaco, Andale Mono, monospace;
  margin: 0 0.2em;
  padding: 0 0.2em;
}
.react-markdown pre {
  line-height: 1.7em;
  overflow: auto;
  padding: 6px 10px;
  border-left: 5px solid var(--topic);
}
.react-markdown code {
  background-color: var(--bg-grey);
}
.react-markdown pre {
  background-color: var(--bg-light-grey);
}
.react-markdown pre > code {
  background-color: var(--bg-light-grey);
  border: 0;
  display: inline;
  padding: 0;
  margin: 0;
  overflow: initial;
  line-height: inherit;
  font-size: 0.85em;
  white-space: pre;
  background: 0 0;
}
.react-markdown code {
  color: var(--light-black);
}
.react-markdown aside {
  display: block;
  float: right;
}
.react-markdown blockquote {
  border-left: 0.3em solid var(--border-grey);
  padding: 0 0 0 0.7em;
  margin-left: 0;
}
.react-markdown blockquote cite {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
}
.react-markdown blockquote cite:before {
  content: '\2014 \00A0';
}
.react-markdown blockquote p {
  color: #666;
}
.react-markdown hr {
  text-align: left;
  color: var(--grey);
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: var(--bg-grey);
  border: 0 none;
}
.react-markdown dl {
  padding: 0;
}
.react-markdown dl dt {
  padding: 10px 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
}
.react-markdown dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.react-markdown .react-markdown dd {
  margin-left: 0;
}
.react-markdown button,
.react-markdown input,
.react-markdown select,
.react-markdown textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;

  *vertical-align: middle;
}
.react-markdown button,
.react-markdown input {
  line-height: normal;

  *overflow: visible;
}
.react-markdown button::-moz-focus-inner,
.react-markdown input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.react-markdown button,
.react-markdown input[type='button'],
.react-markdown input[type='reset'],
.react-markdown input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}
.react-markdown input[type='checkbox'],
.react-markdown input[type='radio'] {
  cursor: pointer;
} /* override default chrome & firefox settings */
.react-markdown input:not([type='image']),
.react-markdown textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.react-markdown input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.react-markdown input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.react-markdown label,
.react-markdown input,
.react-markdown select,
.react-markdown textarea {
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 18px;
}
.react-markdown input[type='checkbox'],
.react-markdown input[type='radio'] {
  cursor: pointer;
  margin-bottom: 0;
}
.react-markdown input[type='text'],
.react-markdown input[type='password'],
.react-markdown textarea,
.react-markdown select {
  display: inline-block;
  padding: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  height: 18px;
  color: #808080;
  border: 1px solid var(--border-grey);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.react-markdown select,
.react-markdown input[type='file'] {
  height: 27px;
  line-height: 27px;
}
.react-markdown textarea {
  height: auto;
} /* grey out placeholders */
.react-markdown :-moz-placeholder {
  color: var(--bg-grey);
}
.react-markdown ::-webkit-input-placeholder {
  color: var(--bg-grey);
}
.react-markdown input[type='text'],
.react-markdown input[type='password'],
.react-markdown select,
.react-markdown textarea {
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.react-markdown input[type='text']:focus,
.react-markdown input[type='password']:focus,
.react-markdown textarea:focus {
  outline: none;
  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
} /* buttons */
.react-markdown button {
  display: inline-block;
  padding: 4px 14px;
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-size: 13px;
  line-height: 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--topic);
  background-repeat: repeat-x;
  background-image: -khtml-gradient(linear, left top, left bottom, from(#049cdb), to(var(--topic)));
  background-image: -moz-linear-gradient(top, #049cdb, var(--topic));
  background-image: -ms-linear-gradient(top, #049cdb, var(--topic));
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #049cdb),
    color-stop(100%, var(--topic))
  );
  background-image: -webkit-linear-gradient(top, #049cdb, var(--topic));
  background-image: -o-linear-gradient(top, #049cdb, var(--topic));
  background-image: linear-gradient(top, #049cdb, var(--topic));
  color: var(--white);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #004b9a;
  border-bottom-color: #003f81;
  -webkit-transition: 0.1s linear all;
  -moz-transition: 0.1s linear all;
  transition: 0.1s linear all;
  border-color: var(--topic) var(--topic) #003f81;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.react-markdown button:hover {
  color: var(--white);
  background-position: 0 -15px;
  text-decoration: none;
}
.react-markdown button:active {
  -webkit-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.react-markdown button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.react-markdown table {
  *border-collapse: collapse; /* IE7 and lower */
  border-spacing: 0;
}
.react-markdown table {
  border: solid var(--border-grey) 1px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-size: 15px;
}
.react-markdown table tr:hover {
  background: #fbf8e9;
  -o-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.react-markdown table td,
.react-markdown .table th {
  border-left: 1px solid var(--border-grey);
  border-top: 1px solid var(--border-grey);
  padding: 10px;
  text-align: left;
}
.react-markdown table th {
  background-color: #dce9f9;
  border-top: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  padding: 7px 10px;
}
.react-markdown table td:first-child,
.react-markdown table th:first-child {
  border-left: none;
}
.react-markdown table th:first-child {
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
}
.react-markdown table th:last-child {
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
}
.react-markdown table th:only-child {
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.react-markdown table tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 6px;
  -webkit-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}
.react-markdown table tr:last-child td:last-child {
  -moz-border-radius: 0 0 6px 0;
  -webkit-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
}
