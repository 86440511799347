.c-algo-pin-modal .search-container {
  padding: 10px 0;
}

.c-algo-pin-modal .checkbox-container {
}

.c-algo-pin-modal .checkbox-container .checkbox-item {
  display: block;
  margin: 10px 0;
}

.c-algo-pin-modal .pagi-container {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
