.c-algo-card .ant-card-head-wrapper .ant-card-head-title {
  max-width: calc(100% - 127px);
}

.c-algo-card .title {
  display: flex;
  align-items: center;
}

.c-algo-card .title .head-pic {
  margin-right: 0.5em;
}

.c-algo-card .title .title-text {
  margin-right: 0.5em;
}

.c-algo-card .ant-card-head {
  position: relative;
  z-index: 1;
  border-bottom: 0;
  background: linear-gradient(rgba(87, 87, 87, 0.5), rgba(87, 87, 87, 0));
  color: #fff;
}

.c-algo-card .ant-card-head .ant-tag-default {
  color: #fff;
}

.c-algo-card .ant-card-cover {
  margin-top: -51px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.c-algo-card .ant-card-cover img {
  position: relative;
  z-index: 0;
  object-fit: cover;
  min-height: 100%;
  filter: brightness(90%);
  border: 0;
}

.c-algo-card .algo-desc {
  margin: 0 0 1em;
  height: 3em;
}

.c-algo-card .remark-info {
  display: flex;
  color: var(--grey);
  font-size: 13px;
}

.c-algo-card .remark-info .remark-info-item {
  margin-right: 1em;
}

@media screen and (max-width: 1200px) {
  .c-algo-card .ant-card-head {
    padding: 0 18px;
  }
  .c-algo-card .ant-card-body {
    padding: 12px 18px;
  }
}

@media screen and (max-width: 900px) {
  .c-algo-card .ant-card-head {
    padding: 0 10px;
  }
  .c-algo-card .ant-card-body {
    padding: 8px 10px;
  }
}
