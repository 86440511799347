.c-member-list .title-divider {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.c-member-list .title-divider .divider {
  flex: 1;
  margin-right: 20px;
}

.c-member-list .member-container {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.c-member-list .member-container .member-item {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .c-member-list .member-container {
    grid-template-columns: 33% 33% 33%;
  }
}

@media screen and (max-width: 1200px) {
  .c-member-list .member-container {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 900px) {
  .c-member-list .member-container {
    grid-template-columns: 100%;
  }
}

.c-member-list .member-container .member-item .user-subname {
  font-size: 13px;
  font-weight: normal;
  color: var(--grey);
}

.c-member-list .member-empty {
  padding: 30px 0;
}
