.p-algorithm-detail {
  margin-top: 15px;
}

.p-algorithm-detail .top-container {
  width: 100%;
  padding: 15px 15px 0;
  box-sizing: border-box;
  background-color: var(--bg-light-grey);
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
}

.p-algorithm-detail .top-container .detail-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 5px;
}

.p-algorithm-detail .top-container .detail-wrapper .ant-tag {
  padding: 0 10px;
  line-height: 25px;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box {
  margin-bottom: 10px;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-institution {
  color: var(--grey);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-institution .icon {
  color: var(--topic);
  font-size: 20px;
  margin-right: 0.3em;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-institution:hover {
  color: var(--topic);
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-group {
  color: var(--grey);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-group:hover {
  color: var(--topic);
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-user {
  color: var(--grey);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-user .icon {
  color: var(--topic);
  font-size: 20px;
  margin-right: 0.3em;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-user:hover {
  color: var(--topic);
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-algo {
  color: var(--black);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-algo .icon {
  color: var(--topic);
  font-size: 20px;
  margin-right: 0.3em;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-algo:hover {
  color: var(--topic);
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .algo-body-item-algo .algo-body-item-algo-version {
  margin: 0 0.3em;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-body-box .divider {
  font-size: 20px;
  color: var(--border-grey);
  margin: 0 0.3em;
}

.p-algorithm-detail .top-container .detail-wrapper .algo-tag-box {
  margin-bottom: 10px;
}

.p-algorithm-detail .top-container .tab-wrapper {
  margin: 5px -15px 0;
}

.p-algorithm-detail .top-container .tab-wrapper .ant-tabs-tab:first-child {
  margin-left: 30px;
}

.p-algorithm-detail .top-container .tab-wrapper .ant-tabs-nav {
  margin: 0;
}

.p-algorithm-detail .top-container .tab-wrapper .ant-tabs-nav::before {
  border: 0;
}

.p-algorithm-detail .algo-detail-container {
  width: 100%;
  display: flex;
  margin: 15px 0;
}

.p-algorithm-detail .algo-detail-container .left-container {
  flex: 1;
  margin-right: 15px;
  max-width: calc(100% - 320px);
}

.p-algorithm-detail .algo-detail-container .left-container.full-width {
  margin-right: 0;
  max-width: 100%;
}

@media screen and (max-width: 900px) {
  .p-algorithm-detail .algo-detail-container .left-container {
    margin-left: 15px;
    max-width: calc(100% - 30px);
  }
}

.p-algorithm-detail .algo-detail-container .left-container .api-box {
  padding: 15px 30px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.p-algorithm-detail .algo-detail-container .left-container .document-box {
  padding: 15px 30px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

@media screen and (max-width: 900px) {
  .p-algorithm-detail .algo-detail-container .left-container .document-box {
    padding: 30px 15px;
  }
}

.p-algorithm-detail .algo-detail-container .left-container .document-empty {
  padding: 30px 0;
}

.p-algorithm-detail .algo-detail-container .right-container {
  width: 320px;
  border-left: 1px solid var(--border-light-grey);
}

@media screen and (max-width: 900px) {
  .p-algorithm-detail .algo-detail-container .right-container {
    display: none;
  }
}

.p-algorithm-detail .algo-detail-container .right-container .content-box {
  padding: 0 10px 10px 28px;
}

.p-algorithm-detail .algo-detail-container .right-container .tags-line {
  margin-top: 15px;
  margin-bottom: -8px;
}

.p-algorithm-detail .algo-detail-container .right-container .tags-line .ant-tag {
  margin-bottom: 8px;
}

.p-algorithm-detail .algo-detail-container .right-container .algo-desc-box {
}

.p-algorithm-detail .algo-detail-container .right-container .algo-desc-line {
  color: var(--grey);
  font-size: 14px;
}

.p-algorithm-detail .algo-detail-container .right-container .algo-desc-line .detail-text {
  margin-left: 0.5em;
}

.p-algorithm-detail .algo-detail-container .right-container .algo-desc-line a {
  color: var(--grey);
  font-size: 14px;
}

.p-algorithm-detail .algo-detail-container .right-container .algo-desc-line a:hover {
  color: var(--topic);
}

.p-algorithm-detail .algo-detail-container .right-container .author-box {
}

.p-algorithm-detail .algo-detail-container .right-container .algo-history-box > span {
  cursor: pointer;
}

.p-algorithm-detail .algo-detail-container .right-container .algo-history-box .ant-tag {
  margin-bottom: 8px;
}
