.algo-setting-container {
  display: flex;
}

.algo-setting-container .algo-setting-menu {
  width: 256px;
  margin-right: 10px;
}
.algo-setting-container .algo-setting-menu .ant-menu {
  border-right: none;
}

.algo-setting-container .algo-setting-content {
  flex: 1;
  padding-bottom: 30px;
  border-left: 1px solid var(--border-light-grey);
}

.algo-setting-container .algo-setting-content .algo-setting-content-body {
  padding: 0 30px;
}

.algo-setting-container .algo-setting-content .algo-setting-content-body .form-wrapper {
  max-width: 400px;
}

.algo-setting-container .algo-setting-content .algo-setting-content-body .form-wrapper.document-form-wrapper {
  max-width: 1000px;
}

.algo-setting-container .algo-setting-content .algo-setting-content-body .form-title {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 0 0 5px 0;
}

.algo-setting-container .algo-setting-content .algo-setting-content-body .form-sub-title {
  font-size: 13px;
  color: var(--grey);
  margin-top: -5px;
  padding: 0 0 5px 0;
}

/* .algo-setting-container .algo-setting-content .algo-setting-content-body .form-input .ant-input-affix-wrapper {
  padding: 8px 11px;
} */

.algo-setting-container .algo-setting-content .algo-setting-content-body .btn-wrapper {
  padding: 15px 0;
}

.document-preview-box {
  margin: auto;
  padding: 15px 30px;
  max-width: 1000px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.algo-setting-container .tags-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.algo-setting-container .tags-wrapper .ant-form-item {
  margin: 0 0 0 0.5em;
  flex: 1;
}

.algo-setting-container .tags-wrapper .tags-icon {
  font-size: 16px;
  margin-right: 0.5em;
  color: var(--topic);
  cursor: pointer;
}

.algo-setting-container .tags-wrapper .tags-title {
  font-size: 14px;
  margin-right: 1em;
}
