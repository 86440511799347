.c-algorithm-statistic .range-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;
}

.c-algorithm-statistic .chart-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.c-algorithm-statistic .chart-container .chart-item {
  width: calc(50% - 8px);
  height: 300px;
  padding: 15px 0 0 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
  margin: 8px 0;
}

.c-algorithm-statistic .chart-container .chart-item:nth-child(odd) {
  margin-right: 8px;
}

.c-algorithm-statistic .chart-container .chart-item:nth-child(even) {
  margin-left: 8px;
}

@media screen and (max-width: 1200px) {
  .c-algorithm-statistic .chart-container .chart-item {
    width: 100%;
    margin: 8px 0;
  }

  .c-algorithm-statistic .chart-container .chart-item:nth-child(odd) {
    margin-right: 0px;
  }

  .c-algorithm-statistic .chart-container .chart-item:nth-child(even) {
    margin-left: 0px;
  }
}

.c-algorithm-statistic .chart-container .chart-item .chart {
  width: 100%;
  height: 100%;
}
