.c-algo-list .title-divider {
  padding-right: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 900px) {
  .c-algo-list .title-divider {
    padding-right: 15px;
  }
}

.c-algo-list .title-divider .divider {
  flex: 1;
}

.c-algo-list .title-divider .btn,
.c-algo-list .title-divider .selector {
  margin-left: 20px;
}

.c-algo-list .algo-container {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.c-algo-list .algo-container .algo-item {
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .c-algo-list .algo-container .algo-item {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .c-algo-list .algo-container .algo-item {
    /* width: 100%; */
    width: 100vw;
    padding: 10px 15px;
  }
}

.c-algo-list .pagi-container {
  padding: 10px 0 30px;
  display: flex;
  justify-content: center;
}

.c-algo-list .algo-empty {
  padding: 30px 0;
}
