.c-header {
  z-index: 100;
  position: relative;
}

.c-header .container {
  width: 1440px;
  padding: 0 30px;
  box-sizing: border-box;
  margin: auto;
}

.c-header.topic-bg {
  background: var(--topic);
}

@media screen and (max-width: 1440px) {
  .c-header .container {
    width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .c-header .container {
    width: 1024px;
  }
}

@media screen and (max-width: 1024px) {
  .c-header .container {
    width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .c-header .container {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .c-header .container {
    margin: auto 15px;
    width: calc(100% - 30px);
  }
}

.c-header a,
.c-header a:hover {
  text-decoration: none;
}

.c-header header {
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-header header .navbar-header {
  display: flex;
  align-items: center;
}

.c-header header .navbar-header img {
  height: 44px;
  margin-left: -15px;
  border-radius: 8px;
}

.c-header header .navbar-header span {
  margin-left: 10px;
  font-size: 20px;
  color: var(--topic);
  font-weight: bold;
}

.c-header.white-color header .navbar-header span {
  color: var(--white);
}

.c-header header .navbar-right {
  display: flex;
  align-items: center;
}

.c-header header .navbar-right .navbar-btn {
  display: flex;
  align-items: center;
}

.c-header header .navbar-right .navbar-btn-narrow {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-header header .navbar-right .navbar-btn {
    display: none;
  }

  .c-header header .navbar-right .navbar-btn-narrow {
    display: block;
    margin: 0 -24px 0 16px;
  }
}

.c-header header .navbar-right .navbar-btn .navbar-item {
  width: 100px;
  margin: 0 10px;
  text-align: center;
  font-size: 15px;
}

.c-header header .navbar-right .navbar-btn .navbar-item span {
  line-height: 40px;
  padding: 5px;
  color: var(--black);
}

.c-header.white-color header .navbar-right .navbar-btn .navbar-item span {
  color: var(--white);
  opacity: 0.9;
}

.c-header header .navbar-right .navbar-btn .navbar-item.active {
  border-bottom: 2px solid var(--topic);
}

.c-header.white-color header .navbar-right .navbar-btn .navbar-item.active {
  border-bottom: 2px solid var(--yellow);
}

.c-header header .navbar-right .navbar-btn .navbar-item:hover span,
.c-header header .navbar-right .navbar-btn .navbar-item.active span {
  color: var(--topic);
}

.c-header.white-color header .navbar-right .navbar-btn .navbar-item:hover span {
  color: var(--white);
  opacity: 0.8;
}
.c-header.white-color header .navbar-right .navbar-btn .navbar-item.active span {
  color: var(--white);
  font-weight: bold;
  opacity: 1;
}

.c-header header .navbar-right .navbar-login {
  border-left: 1px solid var(--bg-grey);
  margin: 0 -24px 0 16px;
  padding-left: 32px;
}

.c-header.c-header.white-color header .navbar-right .navbar-login {
  border-left: 1px solid var(--bg-deep-grey);
}

@media screen and (max-width: 768px) {
  .c-header header .navbar-right .navbar-login {
    border: none;
    margin: 0;
    padding-left: 0;
  }
}
