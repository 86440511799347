.c-user-card .user-card-title {
  display: flex;
  cursor: pointer;
}

.c-user-card .user-card-title .user-head-pic {
  margin-right: 10px;
}

.c-user-card .user-card-title .user-name {
  color: var(--black);
  font-weight: bold;
  font-size: 16px;
}

.c-user-card .user-card-title .user-name .user-subname {
  color: var(--grey);
  font-size: 13px;
  font-weight: normal;
}

.c-user-card .institution-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.c-user-card .institution-container a {
  color: inherit;
}

.c-user-card .institution-container .divider {
  font-size: 14px;
  margin: 0 0.5em;
  color: var(--grey);
}

.c-user-card .institution-container .institution-card {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.c-user-card .institution-container .institution-card .institution-title {
  font-weight: bold;
  color: var(--black);
}

.c-user-card .institution-container .institution-card .institution-head-pic {
  margin: 0 5px 0 0;
}

.c-user-card .institution-container .group-card {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.c-user-card .institution-container .group-card .group-head-pic {
  margin: 0 5px 0 0;
}
