.c-login-entry {
  cursor: pointer;
  transition: transform 0.2s;
}

.c-login-entry:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .c-login-entry:hover {
    transform: none;
  }
}
