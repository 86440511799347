.p-institution {
  margin-top: 15px;
}

.p-institution .institution-divider {
  padding-top: 15px;
}

.p-institution .institution-container .institution-item {
  margin: 25px 0;
}

.p-institution .institution-container .institution-item .institution-body-box {
  flex: 1;
  display: flex;
}

.p-institution .institution-container .institution-item .institution-body-box .ant-avatar {
  width: 140px;
  height: 140px;
}

.p-institution .institution-container .institution-item .institution-body-box .institution-head-pic {
  margin: 0 20px 0 40px;
}

@media screen and (max-width: 900px) {
  .p-institution .institution-container .institution-item .institution-body-box .institution-head-pic {
    margin: 0 10px 0 20px;
  }
}

.p-institution .institution-container .institution-item .institution-body-box .institution-info {
}

.p-institution .institution-container .institution-item .institution-body-box .institution-info .institution-title {
  margin: 10px 0;
  font-size: 22px;
  color: var(--black);
  font-weight: bold;
}

.p-institution
  .institution-container
  .institution-item
  .institution-body-box
  .institution-info
  .institution-description {
  font-size: 14px;
}

.p-institution .institution-container .institution-item .institution-body-box .institution-info .ant-typography {
  color: var(--grey);
}
