.p-user-page {
  margin-top: 15px;
}

.p-user-page .layout-container {
  display: flex;
}

.p-user-page .layout-container .side-layout {
  width: 300px;
  padding: 15px 15px 15px 0;
  box-sizing: border-box;
  /* border-right: 1px solid var(--border-light-grey); */
}

@media screen and (max-width: 900px) {
  .p-user-page .layout-container .side-layout {
    display: none;
  }
}

.p-user-page .layout-container .side-layout .user-card-container {
  margin: 0 0 20px 0;
}

.p-user-page .layout-container .side-layout .user-card-container .ant-card-cover {
  margin: 0;
}

.p-user-page .layout-container .side-layout .user-card-container .user-head {
  max-height: 350px;
  object-fit: cover;
}

.p-user-page .layout-container .side-layout .user-card-container .ant-card-meta-title {
  font-size: 20px;
}

.p-user-page .layout-container .side-layout .user-card-container .user-subname {
  color: var(--grey);
  font-size: 13px;
  font-weight: normal;
}

.p-user-page .layout-container .side-layout .user-card-container .tag-line {
  margin-bottom: 3px;
}

.p-user-page .layout-container .side-layout .user-card-container .tag-line .ant-tag {
  margin-bottom: 8px;
}

.p-user-page .layout-container .side-layout .user-card-container .detail-line {
  color: var(--grey);
  font-size: 14px;
  font-weight: normal;
}

.p-user-page .layout-container .side-layout .user-card-container .detail-line a {
  color: var(--grey);
}

.p-user-page .layout-container .side-layout .user-card-container .detail-line .detail-text {
  margin-left: 0.5em;
}

.p-user-page .layout-container .side-layout .divider-container {
  margin-top: 40px;
}

.p-user-page .layout-container .side-layout .common-card-container {
  margin: 20px 0;
}

.p-user-page .layout-container .side-layout .common-card-container a {
  color: inherit;
}

/* .p-user-page .layout-container .side-layout .user-card-container .ant-typography, */
.p-user-page .layout-container .side-layout .common-card-container .ant-typography {
  color: var(--grey);
}

.p-user-page .layout-container .body-layout {
  padding: 15px 0;
  flex: 1;
}
