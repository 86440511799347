.p-publish {
  padding-top: 15px;
}

.p-publish .publish-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.p-publish .publish-container .publish-divider {
  margin: 0 0 20px;
}

.p-publish .publish-container .publish-selector {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.p-publish .publish-container .document-box {
  padding: 15px 30px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.p-publish .publish-container .deploy-box {
  margin: 30px 0;
}

.p-publish .publish-container .deploy-box.disable {
  opacity: 0.5;
  pointer-events: none;
}
