.c-cloud-deploy-file .file-list-container {
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
  overflow: hidden;
}

.c-cloud-deploy-file .file-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}

.c-cloud-deploy-file .file-menu .file-crumb {
  font-size: 14px;
}

.c-cloud-deploy-file .file-menu .file-crumb .crumb-divider {
  color: var(--grey);
  padding: 0 0.5em;
}

.c-cloud-deploy-file .file-menu .file-crumb .crumb-item {
  color: var(--grey);
  cursor: pointer;
}

.c-cloud-deploy-file .file-menu .file-crumb .crumb-item.active,
.c-cloud-deploy-file .file-menu .file-crumb .crumb-item:last-child {
  color: var(--topic);
}

.c-cloud-deploy-file .file-menu .btn-wrap {
  display: flex;
}

.c-cloud-deploy-file .file-list-container .file-list-item-box {
  cursor: pointer;
  padding: 0 15px;
}

.c-cloud-deploy-file .file-list-container .file-list-item-box:hover {
  background-color: var(--bg-light-grey);
}

.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item {
  display: flex;
  height: 45px;
  align-items: center;
  font-size: 14px;
}

.c-cloud-deploy-file .file-list-container .file-list-item-box:not(:last-child) .file-list-item {
  border-bottom: 1px solid var(--border-light-grey);
}

.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-icon {
  width: 2em;
}
.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-icon .file-folder {
  font-size: 1.2em;
}
.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-icon .file-file {
  padding: 0.1em;
}

.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-name {
  width: 50%;
}

.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-size {
  flex: 1;
  color: var(--grey);
}

.c-cloud-deploy-file .file-list-container .file-list-item-box .file-list-item .file-time {
  color: var(--grey);
}

.c-cloud-deploy-file .file-content-container .file-content-disable {
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.c-cloud-deploy-file .file-content-container .file-content-box .ant-input-disabled {
  background-color: unset;
  color: unset;
}
