.c-algo-selector .selector-group {
  margin: 20px 0;
  padding: 15px;
  background-color: var(--bg-light-grey);
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
}

.c-algo-selector .selector-group .selector-group-name {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  margin-bottom: 10px;
}

.c-algo-selector .selector-group .selector-tags-box {
  margin-bottom: 10px;
}

.c-algo-selector .selector-group .selector-tags-box .ant-tag {
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
}

.c-algo-selector .selector-group .selector-tags-box .ant-tag:not(.selected):hover {
  opacity: 0.7;
}
