.p-group-page {
  margin-top: 15px;
}

.p-group-page .top-container {
  width: 100%;
  padding: 15px 15px 0;
  box-sizing: border-box;
  background-color: var(--bg-light-grey);
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
}

.p-group-page .top-container .detail-wrapper {
  display: flex;
  margin-bottom: 20px;
}
.p-group-page .top-container .detail-wrapper .group-body-box {
  flex: 1;
  display: flex;
}

.p-group-page .top-container .detail-wrapper .group-body-box .ant-avatar {
  width: 140px;
  height: 140px;
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-head-pic {
  margin: 0 20px 0 40px;
}

@media screen and (max-width: 900px) {
  .p-group-page .top-container .detail-wrapper .group-body-box .group-head-pic {
    margin: 0 10px 0 20px;
  }
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-info {
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-info .group-title {
  margin: 10px 0;
  font-size: 22px;
  color: var(--black);
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-info .group-line {
  margin-bottom: 3px;
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-info .group-line .ant-tag {
  margin-bottom: 8px;
}

.p-group-page .top-container .detail-wrapper .group-body-box .group-info .group-description {
  font-size: 14px;
}

.p-group-page .top-container .tab-wrapper {
  padding: 0 15px;
}

.p-group-page .top-container .tab-wrapper .ant-tabs-nav {
  margin: 0;
}

.p-group-page .top-container .tab-wrapper .ant-tabs-nav::before {
  border: 0;
}

.p-group-page .group-page-container {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.p-group-page .group-page-container .side-layout {
  width: 300px;
  padding: 15px 15px 15px 0;
  box-sizing: border-box;
  /* border-right: 1px solid var(--border-light-grey); */
}

@media screen and (max-width: 900px) {
  .p-group-page .group-page-container .side-layout {
    display: none;
  }
}

.p-group-page .group-page-container .side-layout .divider-container {
  /* margin-top: 40px; */
}

.p-group-page .group-page-container .side-layout .common-card-container {
  margin: 20px 0;
}

.p-group-page .group-page-container .side-layout .common-card-container a {
  color: inherit;
}

.p-group-page .group-page-container .side-layout .common-card-container.member-card-container {
  text-align: center;
}

.p-group-page .group-page-container .side-layout .common-card-container .ant-typography {
  color: var(--grey);
}

.p-group-page .group-page-container .body-layout {
  padding: 15px 0;
  flex: 1;
}
