.c-cloud-deploy-process .log-container {
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0;
}

.c-cloud-deploy-process .log-container .status-box {
  background-color: rgb(87, 93, 97);
  color: rgb(227, 227, 227);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.c-cloud-deploy-process .log-container .status-box .red {
  color: var(--red);
}

.c-cloud-deploy-process .log-container .log-box {
  background-color: #383b3d;
  color: rgb(209, 209, 209);
  font-size: 14px;
  line-height: 1.5;
  height: 500px;
  padding: 15px;
  overflow: scroll;
  white-space: pre-wrap;
}
.c-cloud-deploy-process .btn-wrapper {
  display: flex;
  margin: 15px 0;
}

.c-cloud-deploy-process .chart-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.c-cloud-deploy-process .chart-container .chart-item {
  width: calc(50% - 8px);
  height: 300px;
  padding: 15px 0 0 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
  margin: 8px 0;
}

.c-cloud-deploy-process .chart-container .chart-item:nth-child(odd) {
  margin-right: 8px;
}

.c-cloud-deploy-process .chart-container .chart-item:nth-child(even) {
  margin-left: 8px;
}

@media screen and (max-width: 1200px) {
  .c-cloud-deploy-process .chart-container .chart-item {
    width: 100%;
    margin: 8px 0;
  }

  .c-cloud-deploy-process .chart-container .chart-item:nth-child(odd) {
    margin-right: 0px;
  }

  .c-cloud-deploy-process .chart-container .chart-item:nth-child(even) {
    margin-left: 0px;
  }
}

.c-cloud-deploy-process .chart-container .chart-item .chart {
  width: 100%;
  height: 100%;
}
