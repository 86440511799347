.c-cloud-delpoy .steps-container {
  max-width: 500px;
  margin: 30px auto;
  text-align: center;
}

.c-cloud-delpoy .steps-container .ant-steps-item {
  margin: auto;
}

.c-cloud-delpoy .steps-action {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
