.p-user-setting {
  margin-top: 15px;
}

.p-user-setting .user-setting-container {
  display: flex;
}

.p-user-setting .user-setting-container .user-setting-menu {
  width: 300px;
  margin-right: 10px;
}

.p-user-setting .user-setting-container .user-setting-menu .user-card-wrapper {
  margin: 0 5px 20px;
  padding: 15px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.p-user-setting .user-setting-container .user-setting-menu .ant-menu {
  border-right: none;
}

.p-user-setting .user-setting-container .user-setting-content {
  flex: 1;
  padding-bottom: 30px;
  border-left: 1px solid var(--border-light-grey);
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body {
  padding: 0 30px;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .form-wrapper {
  max-width: 400px;
}

.p-user-setting
  .user-setting-container
  .user-setting-content
  .user-setting-content-body
  .form-wrapper.document-form-wrapper {
  max-width: 1000px;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .block-wrapper {
  margin: 20px 0;
  padding: 15px 10px;
  background-color: var(--bg-light-grey);
  border-radius: 8px;
  border: 1px solid var(--border-light-grey);
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .block-title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 0 0 10px 0;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .block-tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: var(--grey);
  padding: 10px 0 0 0;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .block-title .text-btn {
  color: var(--topic);
  font-size: 14px;
  cursor: pointer;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .form-title {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 0 0 5px 0;
}

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .form-upload {
  margin-bottom: 18px;
}

/* .p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .form-input .ant-input-affix-wrapper {
  padding: 8px 11px;
} */

.p-user-setting .user-setting-container .user-setting-content .user-setting-content-body .btn-wrapper {
  padding: 15px 0;
}

.document-preview-box {
  margin: auto;
  padding: 15px 30px;
  max-width: 1000px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}

.p-user-setting .tags-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.p-user-setting .tags-wrapper .ant-form-item {
  margin: 0 0 0 0.5em;
  flex: 1;
}

.p-user-setting .tags-wrapper .tags-icon {
  font-size: 16px;
  margin-right: 0.5em;
  color: var(--topic);
  cursor: pointer;
}

.p-user-setting .tags-wrapper .tags-title {
  font-size: 14px;
  margin-right: 1em;
}
