.body {
  width: 1440px;
  margin: auto;
  margin-bottom: 60px;
}

@media screen and (max-width: 1440px) {
  .body {
    width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  .body {
    width: 1024px;
  }
}

@media screen and (max-width: 1024px) {
  .body {
    width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .body {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .body {
    width: 100%;
  }
}
