.c-algo-pin-modal .type-selector {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.c-algo-pin-modal .gradio-exp-container {
  border: 1px solid var(--border-grey);
  border-radius: 8px;
  height: 300px;
  overflow: hidden;
}
