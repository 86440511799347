.c-login-drawer .form-title {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 0 0 5px 0;
}

.c-login-drawer .login-form-input .ant-input-affix-wrapper {
  padding: 8px 11px;
}

.c-login-drawer .btn-wrapper {
  padding: 15px 0;
}
