.institution-setting-container {
  display: flex;
}

.institution-setting-container .institution-setting-menu {
  width: 256px;
  margin-right: 10px;
}

.institution-setting-container .institution-setting-menu .ant-menu {
  border-right: none;
}

.institution-setting-container .institution-setting-content {
  flex: 1;
  padding-bottom: 30px;
  border-left: 1px solid var(--border-light-grey);
}

.institution-setting-container .institution-setting-content .institution-setting-content-body {
  padding: 0 30px;
}

.institution-setting-container .institution-setting-content .institution-setting-content-body .form-wrapper {
  max-width: 400px;
}

.institution-setting-container .institution-setting-content .institution-setting-content-body .form-wrapper.document-form-wrapper {
  max-width: 1000px;
}

.institution-setting-container .institution-setting-content .institution-setting-content-body .form-title {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 0 0 5px 0;
}

.institution-setting-container .institution-setting-content .institution-setting-content-body .form-upload {
  margin-bottom: 18px;
}

/* .institution-setting-container .institution-setting-content .institution-setting-content-body .form-input .ant-input-affix-wrapper {
  padding: 8px 11px;
} */

.institution-setting-container .institution-setting-content .institution-setting-content-body .btn-wrapper {
  padding: 15px 0;
}

.document-preview-box {
  margin: auto;
  padding: 15px 30px;
  max-width: 1000px;
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}
