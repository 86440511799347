.cloud-deploy-file-wrapper {
  display: block;
  margin: auto;
  max-width: 1000px;
}

.c-cloud-deploy-config .form-flex-wrapper {
  display: flex;
}

.c-cloud-deploy-config .form-flex-wrapper .form-flex-item {
  flex: 1;
  margin-right: 1em;
}

.c-cloud-deploy-config .form-flex-wrapper .form-flex-item:last-child {
  margin-right: 0em;
}

.c-cloud-deploy-config .select-file {
  cursor: pointer;
}

.c-cloud-deploy-config .select-file .ant-form-item {
  pointer-events: none;
}

.c-cloud-deploy-config .service-param-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.c-cloud-deploy-config .service-param-wrapper .ant-form-item {
  margin: 0 0 0 0.5em;
  flex: 1;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-icon {
  font-size: 16px;
  margin-right: 0.5em;
  color: var(--topic);
  cursor: pointer;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-title {
  font-size: 14px;
  margin-right: 1em;
  width: 3em;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block {
  border-left: 3px solid var(--topic);
  background-color: var(--bg-light-topic);
  padding: 10px;
  flex: 1;
  border-radius: 8px;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block .service-param-block-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block .service-param-block-wrapper .service-param-block-title {
  font-size: 14px;
  margin-right: 1em;
  width: 10em;
  text-align: right;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block .service-param-block-wrapper .service-param-select-file {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block .service-param-block-wrapper .service-param-select-file .ant-form-item {
  pointer-events: none;
}

.c-cloud-deploy-config .service-param-wrapper .service-param-block .service-param-block-wrapper .ant-form-item {
  margin: 0 0 0 0.5em;
  flex: 1;
}
