body {
  /** 主题色 */
  --topic: #5864ff;
  /** 背景主题色 */
  --bg-topic: #ecf0ff;
  /** 背景主题色 */
  --bg-light-topic: #f4f6ff;
  /** 黑色 */
  --black: #222222;
  /** 黑色 */
  --light-black: #505050;
  /** 白色 */
  --white: #ffffff;
  /** 灰色 */
  --grey: #999999;
  /** 红色 */
  --red: #ec5b56;
  /* 黄色 */
  --yellow: #f6d661;
  /** 背景深灰色 */
  --bg-deep-grey: #cccccc;
  /** 背景灰色 */
  --bg-grey: #eeeeee;
  /** 背景浅灰色 */
  --bg-light-grey: #fafafa;
  /** 边框灰色 */
  --border-grey: #e2e2e2;
  /** 边框浅灰色 */
  --border-light-grey: #f0f0f0;
}

a {
  color: var(--topic);
  text-decoration: none;
}

button.circle-btn {
  min-width: 120px;
  height: 40px;
  line-height: 40px;
  background: #32b6f4;
  border: none;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 999px;
}

button.base-btn {
  height: 40px;
  line-height: 40px;
  background: #32b6f4;
  border: none;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  min-width: 100px;
  font-size: 16px;
}

button.cancel {
  background: #eeeeee;
  color: #ababab;
}

button.blue {
  background: #32b6f4;
}

button.red {
  background: #fc8181;
}

button.white {
  background: #fff;
  color: #32b6f4;
  border: 1px solid #32b6f4;
}

button.mini-btn {
  width: 86px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  color: #32b6f4;
}

table th.table-column {
  text-align: center;
  background: #f9fdff;
}

.text-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-cut-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-cut-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text-cut-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.text-cut-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.text-cut-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.op-hover:hover {
  opacity: 0.7;
}
