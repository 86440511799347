.c-algo-rearch .ant-input {
  font-size: 15px !important;
}

.c-algo-rearch {
  position: relative;
}

.c-algo-rearch .tick-menu {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid var(--border-grey);
  overflow: hidden;
  z-index: 100;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 12%);
}
